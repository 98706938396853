import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image margin-top-0"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/img/vit-lake.jpg)`,
            backgroundPosition: `top left`,
            backgroundAttachment: `fixed`,
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '150px',
              lineHeight: '1',
              justifyContent: 'space-around',
              alignItems: 'left',
              flexDirection: 'column',
            }}
          >
            <h1
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
              style={{
                boxShadow:
                  '#f89406 0.5rem 0px 0px, #f89406 -0.5rem 0px 0px',
                backgroundColor: '#f89406',
                color: 'white',
                lineHeight: '1',
                padding: '0.25em',
              }}
            >
              Latest Stories
            </h1>
          </div>
        </div>
        <section className="section columns">
          <div className="column is-10 is-offset-1">
            <BlogRoll />
          </div>
        </section>
      </Layout>
    )
  }
}
